// Black & White
$color-white                            : #FFFFFF;
$color-light                            : #F6FADB;
$color-gray                             : #67686A;
$color-dark                             : #000000;

// Main Color
$color-main                             : #B4C941;
$color-second                           : #0A7F86;
$color-third                            : #C7DFCB;

// If need
// Font
$font-family                            : 'Lato';
$font-family--heading                   : 'Figtree';

// Box-shadow
$shadow                                 : 0px 3px 20px 0px rgba(0, 0, 0, 0.25);

// Border-radius
$border-radius                          : 20px;
$border-radius--second                  : 15px;
$border-radius--input                   : 10px;
$border-radius--image                   : 20px;

// Transition
$duration                               : .25s;
$timing                                 : ease-in-out;

// URL shortner
$urlThemeShort                          : '/wp-content/themes/ternay/assets/src/images/sprite-images/theme-icons/';


@use '../../../plugins/creasit-builder/assets/src/sass/styles.scss' with (

// VARIABLES

    $theme_name                             : 'ternay',

    // Black & White
    $color-light                            : $color-light,
    $color-gray                             : $color-gray,
    $color-dark                             : $color-dark,

    $color-main--rgba                       : rgba($color-main, .9),

    // Main Colors
    $color-main                             : $color-main,
    $color-second                           : $color-second,
    $color-third                            : $color-third,

    // Text color on main color
    $color-text--main                       : $color-dark,

    // Font
    $font-family                            : $font-family,
    $font-family--heading                   : $font-family--heading,
    
    // Border-radius
    $border-radius                          : $border-radius,
    $border-radius--second                  : $border-radius--second,
    $border-radius--input                   : $border-radius--input,
    $border-radius--image                   : $border-radius--image,

    // Card
    $card-bg                                : $color-white,
    $card-bg--hover                         : $color-light,

    $card-shadow                            : 1px 1px 15px 0px rgba(40, 40, 40, 0.10),
    $card-shadow--hover                     : unset,

    // Taxo
    $taxo-bg                                : $color-third,

    // Flexibles
        // Bullet
        $bullet-neutral-color               : $color-gray,


    //======================================================================================================
    // Header
    //======================================================================================================
        $header-height              : 140px,
        $header-padding             : 35px 0,

        $header-height--fixed       : 100px,    
        $header-padding--fixed      : 10px 0,

        $header_nav-gap             : 20px,

        // Model full under top
        $header_m_full_under_top-bg : linear-gradient(180deg, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0.00) 100%),
        $header_m_full_under_top-bg--fixed  : $color-white,
        $header_m_full_under_top-bg--mobile : linear-gradient(180deg, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0.00) 100%),


    //======================================================================================================
    // Tools
    //======================================================================================================
        $tool-bg                                    : $color-third,
        $tool-border-color                          : $color-third,
        $tool-color                                 : $color-dark,
        
            // Hover, Focus
            $tool-bg--hover                         : $color-white,
            $tool-border-color--hover               : $color-third,
            $tool-color--hover                      : $color-dark,
        
            // Header fixed
            $tool-bg--fixed                         : $color-third,
            $tool-border-color--fixed               : $color-third,
            $tool-color--fixed                      : $color-dark,
        
                // Hover, Focus
                $tool-bg--fixed--hover              : $color-white,
                $tool-border-color--fixed--hover    : $color-third,
                $tool-color--fixed--hover           : $color-dark,


    //======================================================================================================
    // Footer
    //======================================================================================================

        // Globals
        $footer-bg                              : $color-light,

        // Logo size
        $footer_logo-width                      : 97px,
        $footer_logo-height                     : 126px,

        // Infos
        $footer_infos-gap                       : 60px,

        // Coord
        $footer_coord-maxwidth                  : 185px,

        // Opening hours
        $footer_opening-maxwidth                : 395px,

        // Subzone visual link
        $footer_subzone_visualink-alignItems        : center,
        $footer_subzone_visualink-alignItems--1200  : center,

        $footer_subzone_visualink-gap               : 10px,
        $footer_subzone_visualink-svg-size          : 60px,
        $footer_subzone_visualink-bg                : $color-main,
        $footer_subzone_visualink-border            : $color-main,
        $footer_subzone_visualink-color             : $color-dark,

        
    //======================================================================================================
    // Menu
    //======================================================================================================
        $bmenu_button_open-fsize        : 1.125rem,
        $bmenu_button_open-bg           : $color-main,
        $bmenu_button_open-border-color : $color-main,
        $bmenu_button_open-color        : $color-dark,

        // Hover, Focus
        $bmenu_button_open-bg--hover                : $color-white,
        $bmenu_button_open-border-color--hover      : $color-main,
        $bmenu_button_open-color--hover             : $color-dark,

        // Header fixed
        $bmenu_button_open-bg--fixed                : $color-main,
        $bmenu_button_open-border-color--fixed      : $color-main,
        $bmenu_button_open-color--fixed             : $color-dark,

            // Hover, Focus
            $bmenu_button_open-bg--fixed--hover             : $color-white,
            $bmenu_button_open-border-color--fixed--hover   : $color-main,
            $bmenu_button_open-color--fixed--hover          : $color-dark,



    //======================================================================================================
    // Home
    //======================================================================================================

        // Homeslideshow
        $m-slideshow_height             : 590px,
        $m-slideshow_height--1200       : 475px,
        $m-slideshow_height--960        : 350px,
        $m-slideshow_height--640        : 330px,

        $m-slideshow_pag_bottom         : 55px,
        $m-slideshow_pag_bottom--640    : 45px,

            // searchBar
            $m-searchbar_btn-bg                 : $color-second,
            $m-searchbar_btn-bd                 : $color-second,
            $m-searchbar_btn-color              : $color-white,

            $m-searchbar_btn-bg--hover          : $color-white,
            $m-searchbar_btn-bd-color--hover    : $color-second,
            $m-searchbar_btn-color--hover       : $color-dark,

            $m-searchbar-justifyContent         : flex-end,
            $m-searchbar-paddingBottom          : 180px,
            
        // Access
        $c_access-alignItems            : center,
        $c_acesss-justifyContent        : center,

        $c_access__image-size           : 80px,

        $c_access__title-fweight        : 600,
        $c_access__title-fsize          : 1rem,
        $c_access__title-max-width      : 120px,

        $m-fqa-classic_section-margin   : 0 0 40px,
        $m-fqa-classic_section-padding  : 0 0 50px,

        $m-fqa-classic_wrapper-margin   : -35px 0 0,

        $m-fqa-classic_list-gap         : 40px,

        $m-fqa-classic_item-size        : 140px,
        $m-fqa-classic_item-size--1200  : 140px,
        $m-fqa-classic_item-size--960   : 140px,
        $m-fqa-classic_item-size--640   : 140px,

        // Posts
        $m-fp-2_slides_btn-bg               : $color-second,
        $m-fp-2_slides_btn-bd-color         : $color-second,
        $m-fp-2_slides_btn-color            : $color-white,
        $m-fp-2_slides_btn-bg--hover        : $color-white,
        $m-fp-2_slides_btn-bd-color--hover  : $color-second,
        $m-fp-2_slides_btn-color--hover     : $color-dark,

        // Events
        $m-fp-1_big_3_slides_btn-bg                 : $color-second,
        $m-fp-1_big_3_slides_btn-bd-color           : $color-second,
        $m-fp-1_big_3_slides_btn-color              : $color-white,
        $m-fp-1_big_3_slides_btn-bg--hover          : $color-white,
        $m-fp-1_big_3_slides_btn-bd-color--hover    : $color-second,
        $m-fp-1_big_3_slides_btn-color--hover       : $color-dark,
        
        $m-fp-1_big_3_slides_btn_prop-bg                : $color-third,
        $m-fp-1_big_3_slides_btn_prop-bd-color          : $color-third,
        $m-fp-1_big_3_slides_btn_prop-color             : $color-dark,
        $m-fp-1_big_3_slides_btn_prop-bg--hover         : $color-white, 
        $m-fp-1_big_3_slides_btn_prop-bd-color--hover   : $color-third,
        $m-fp-1_big_3_slides_btn_prop-color--hover      : $color-dark,

        // Kiosque
        $c_document_image-shadow                : 1px 1px 15px 0px rgba(40, 40, 40, 0.10),

        $c_document_content-bg                  : $color-third,

        $c_document_button-bg                   : $color-white,
        $c_document_button-bd-color             : $color-white,
        $c_document_button-color                : $color-dark,
        $c_document_button-bg--hover            : $color-third,
        $c_document_button-bd-color--hover      : $color-dark,
        $c_document_button-color--hover         : $color-dark,

        $c_document_button_sys-bg               : $color-second,
        $c_document_button_sys-bd-color         : $color-second,
        $c_document_button_sys-color            : $color-white,
        $c_document_button_sys-bg--hover        : $color-white,
        $c_document_button_sys-bd-color--hover  : $color-second,
        $c_document_button_sys-color--hover     : $color-dark,
);

@import 'abstracts/mixins';
@import 'base/font-face';
@import 'components/f-titles';
@import 'components/c-title';
@import 'pages/home';


//======================================================================================================
// Header
//======================================================================================================
header {

    .header {
        &__wrapper {
            border-bottom: 10px solid $color-main;

            .identity {
                &__title {
                    svg {
                        @include size(180px, 70px);
                        fill: $color-second;
                        filter: unset;
                    }
                }
            }
        }
    }

    &.header--fixed {
        .header {
            &__wrapper {
                border-bottom: 10px solid $color-main;

                .identity {
                    &__title {
                        svg {
                            fill: $color-second;
                            filter: unset;
                        }
                    }
                }
            }
        }
    }
}

.home {
    header {
        .header {
            &__wrapper {
                border: 0;

                .identity {
                    &__title {
                        svg {
                            fill: $color-white;
                            filter: drop-shadow(0 0 30px rgba(0,0,0,.549));
                        }
                    }
                }
            }

            .nav-main {
                &--buger {
                    .nav-button {
                        &__menu-open {
                            &:hover, &:focus {
                                border-color: $color-white;
                            }
                        }
                    }
                }
            }
        }
        
        &.header--fixed {
            .header {
                &__wrapper {
                    border-bottom: 10px solid $color-main;
    
                    .identity {
                        &__title {
                            svg {
                                fill: $color-second;
                                filter: unset;
                            }
                        }
                    }
                }
                
                .nav-main {
                    &--buger {
                        .nav-button {
                            &__menu-open {
                                &:hover, &:focus {
                                    border-color: $color-main;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Footer
//======================================================================================================
.footer {
    border-top: 10px solid $color-main;
}