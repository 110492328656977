//======================================================================================================
// Alu
//======================================================================================================
.m-fs-full_screen_under_menu {
    border-bottom: 10px solid $color-main;
}

//======================================================================================================
// Access
//======================================================================================================
.m-fqa-classic {

    &.l-section {
        position: relative;
        display: inline-flex;
        width: 100%;
        z-index: 1;
        background: url('/wp-content/themes/ternay/assets/src/images/motif.png');
    }

    &__wrapper {
        margin-top: -35px;
    }

    .c-access {
        border-radius: $border-radius;
        background-color: $color-white;
        box-shadow: $shadow;
        transition: {
            property: background-color, border-color, color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            background-color: $color-third;
            box-shadow: unset;
        }
    }
}

//======================================================================================================
// Actualité
//======================================================================================================
.m-fp-2_slides--post {
    position: relative;
    z-index: 0;

    &:before {
        content: "";
        z-index: -1;
        @include absolute($right: 0, $bottom: 0, $left: 0);
        @include size(100%, calc(100% - 260px));
        background-color: $color-light;
        margin: 0 auto;
        pointer-events: none;
    }

    &.l-section {
        padding-bottom: 60px;
    }
    
    .l-section__wrapper {
        gap: 35px;
    }

    .swiper {
        padding-bottom: 35px;

        .card__container {
            &:hover, &:focus {
                .card__content {
                    background-color: $color-third;
                }
            }
        }
    }

    .l-section__buttons {
        margin-top: -35px;
    }
}

//======================================================================================================
// Events
//======================================================================================================
.m-fp-1_big_3_slides--events {
    position: relative;
    z-index: 0;

    &.l-section {
        padding: 80px 0 60px;
    }

    &:before {
        content: "";
        z-index: -1;
        @include absolute($right: 0, $bottom: 0, $left: 0);
        @include size(100%, calc(100% - 330px));
        background: url('/wp-content/themes/ternay/assets/src/images/motif.png');
        margin: 0 auto;
        pointer-events: none;
    }

    .m-fp-1_big_3_slides {
        &__content {
            gap: 35px;

            .swiper {
                padding-bottom: 35px;
            }
        }

        &__footer {
            margin-top: -35px;
        }
    }
}

//======================================================================================================
// Kiosque
//======================================================================================================
.m-fd-2_documents {

    &.l-section {
        padding: 80px 0 60px;
    }
}