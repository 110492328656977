.c-title {
    position: relative;
    color: $color-second;
    
    &::after {
        content: "";
        @include absolute($top: -18px, $left: -7px);
        z-index: -1;
        @include size(35px, 24px);
        mask-image: url($urlThemeShort + 'deco_titre.svg');
        background-color: $color-main;
        pointer-events: none;
    }
}