// Nothing to change here
.acf-fields h2, form h2, .contextual-publications h2 {
    @extend .bloc-title;
    @extend .h2;
}

.bloc-title {
    &.h2, &.h3, &.h4 {
        position: relative;
        color: $color-second;
    }

    &.h2 {
        margin-top: 70px;
        
        &::before {
            content: "";
            @include absolute($top: -20px, $left: -19px);
            z-index: -1;
            @include size(35px, 24px);
            mask-image: url($urlThemeShort + 'deco_titre.svg');
            background-color: $color-main;
            pointer-events: none;
        }
    }

    &.h3 {
        &:before {
            content: "";
            @include absolute($bottom: -5px, $left: 0);
            @include size(50px, 2px);
            border-radius: 5px;
            background-color: $color-main;
            pointer-events: none;
        }
    }
}